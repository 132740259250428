export enum CollectionLogic {
    Regular = 0,
    Sequential = 1,
    Branching = 2
}

export interface IProject {
    projectName?: string;
    project_id: string;
    shortDescription?: string;
    website?: string;
    icon?: string;
    pins?: string[];
    tags: {
        subjectTag: string[];
        contentTag: string;
    };
    permission?: string[];
    isAvailable?: boolean;
    isQREnabled?: boolean;
    collectionLogic?: CollectionLogic;
    audioSize: number;
    projectOwnerUserKey: string;
    isResponseEnabled?: boolean;
    isTransitProjectEnabled?: boolean;
    transitAlbumKey?: string | null; 
    isPrivate?: boolean;
    isBranching?: boolean;
}