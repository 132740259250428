import React from "react";
import { TextField, Button, Checkbox, Select, MenuItem } from "@mui/material";
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import "./InputForms.css";
import FormButtons from './FormButtons';
import { CollectionLogic } from "../types";

interface ProjectFormProps {
  projectName: string;
  setProjectName: React.Dispatch<React.SetStateAction<string>>;
  projectOwnerUserKey: string;
  setProjectOwnerUserKey: React.Dispatch<React.SetStateAction<string>>;
  projectIconUrl: string;
  selectedFile: File | null;
  setSelectedFile: React.Dispatch<React.SetStateAction<File | null>>;
  onFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isQREnabled: boolean;
  setIsQREnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isResponseEnabled: boolean;
  setIsResponseEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isTransitProjectEnabled: boolean;
  setIsTransitProjectEnabled: React.Dispatch<React.SetStateAction<boolean>>;
  isUploading: boolean;
  showDelete: boolean;
  onCancel: () => void;
  onSave: () => Promise<void>;
  onDelete?: () => Promise<void>;
  collectionLogic: CollectionLogic;
  setCollectionLogic: React.Dispatch<React.SetStateAction<CollectionLogic>>;
}

const ProjectForm: React.FC<ProjectFormProps> = ({
  projectName,
  setProjectName,
  projectOwnerUserKey,
  setProjectOwnerUserKey,
  projectIconUrl,
  selectedFile,
  setSelectedFile,
  onFileChange,
  isQREnabled,
  setIsQREnabled,
  isResponseEnabled,
  setIsResponseEnabled,
  isTransitProjectEnabled,
  setIsTransitProjectEnabled,
  isUploading,
  showDelete,
  onCancel,
  onSave,
  onDelete,
  collectionLogic,
  setCollectionLogic,
}) => {
  return (
    <>
      <TextField
        className="input-container"
        color="primary"
        label="User Key"
        size="medium"
        placeholder="User Key"
        variant="filled"
        type="text"
        value={projectOwnerUserKey}
        onChange={(event) => setProjectOwnerUserKey(event.target.value)}
      />
      <TextField
        className="input-container"
        color="primary"
        label="Project Name"
        size="medium"
        placeholder="Project Name"
        variant="filled"
        type="text"
        value={projectName}
        onChange={(event) => setProjectName(event.target.value)}
      />
      <div className="image-uploader">
        <img className="icon" alt="Project Icon" src={projectIconUrl} />
        <Button
          className="button-action-submit"
          color="primary"
          size="medium"
          variant="contained"
          component="label"
        >
          Change Icon
          <input
            type="file"
            hidden
            accept="image/*"
            onChange={onFileChange}
          />
        </Button>
      </div>
      <div className="check-toggle">
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ color: 'black', fontSize: 24 }} />}
          checkedIcon={<CheckBoxIcon style={{ color: 'black', fontSize: 24 }} />}
          checked={isQREnabled}
          onChange={(event) => setIsQREnabled(event.target.checked)}
        />
        <a className="qr-codes">QR Codes</a>
      </div>
      <div className="check-toggle">
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ color: 'black', fontSize: 24 }} />}
          checkedIcon={<CheckBoxIcon style={{ color: 'black', fontSize: 24 }} />}
          checked={isResponseEnabled}
          onChange={(event) => setIsResponseEnabled(event.target.checked)}
        />
        <a className="response-enabled">Enable Responses</a>
      </div>
      <div className="check-toggle">
        <Checkbox
          icon={<CheckBoxOutlineBlankIcon style={{ color: 'black', fontSize: 24 }} />}
          checkedIcon={<CheckBoxIcon style={{ color: 'black', fontSize: 24 }} />}
          checked={isTransitProjectEnabled}
          onChange={(event) => setIsTransitProjectEnabled(event.target.checked)}
        />
        <a className="transit-project">Enable Transit Project</a>
      </div>
      <div className="dropdown-container">
        <Select
          value={collectionLogic}
          onChange={(event) => setCollectionLogic(event.target.value as CollectionLogic)}
          variant="filled"
          className="collection-logic-dropdown"
        >
          <MenuItem value={CollectionLogic.Regular}>Regular</MenuItem>
          <MenuItem value={CollectionLogic.Sequential}>Sequential</MenuItem>
          <MenuItem value={CollectionLogic.Branching}>Branching</MenuItem>
        </Select>
      </div>
      <FormButtons
        onCancel={onCancel}
        onSave={onSave}
        onDelete={onDelete}
        isUploading={isUploading}
        showDelete={showDelete}
      />
    </>
  );
};

export default ProjectForm;