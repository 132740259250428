import { db } from '../firebase/firebase';
import { collection, getDocs, doc, updateDoc, getDoc, where, query, setDoc, deleteDoc, writeBatch, addDoc, arrayRemove } from 'firebase/firestore';
import { IPin } from '../types/Pin';
import { IRecording } from '../types/Recording';
import { TagManager } from './TagManager';
import { ProjectManager } from './ProjectManager';


export const PinManager = {
  pinIconCache: new Map<string, string>(),

  observePins: async (): Promise<IPin[]> => {
    try {
      const pinsRef = collection(db, 'Pins');
      const querySnapshot = await getDocs(pinsRef);
      const pins: IPin[] = querySnapshot.docs.map(doc => doc.data() as IPin);
      return pins;
    } catch (error) {
      console.error("Error fetching pins: ", error);
      return [];
    }
  },


  updatePinLocation: async (pinKey: string, lat: number, lng: number): Promise<void> => {
    if (!pinKey) {
      console.error('pinKey is undefined.');
      return;
    }
    const pinRef = doc(db, 'Pins', pinKey);
    try {
      await updateDoc(pinRef, {
        'location.geoPoint.lat': lat, // Update the latitude
        'location.geoPoint.long': lng // Update the longitude
      });
    } catch (error) {
      console.error("Error updating pin location: ", error);
      throw error;
    }
  },
  getPin: async (pinKey: string): Promise<IPin | null> => {
    try {
      if (!pinKey || pinKey.trim() === '') {
        console.warn("Invalid pinKey provided:", pinKey);
        return null;
      }

      const pinRef = doc(db, 'Pins', pinKey);
      const pinDoc = await getDoc(pinRef);
      
      if (!pinDoc.exists()) {
        console.warn(`No pin found with key: ${pinKey}`);
        return null;
      }

      const pinData = pinDoc.data() as IPin;
      return {
        ...pinData,
        pinKey: pinDoc.id // Ensure pinKey is set
      };
    } catch (error) {
      console.error("Error fetching pin:", error);
      return null;
    }
  },

  removePin: async (pinKey: string): Promise<void> => {
    try {
      // First, get the pin data to know which project it belongs to
      const pin = await PinManager.getPin(pinKey);
      if (!pin) {
        console.error(`Pin with key ${pinKey} not found`);
        return;
      }

      // Get the project data
      const project = await ProjectManager.getProject(pin.project || '');
      if (!project) {
        console.error(`Project for pin ${pinKey} not found`);
        return;
      }

      // Remove the pin from all project tags
      if (project.tags && project.tags.subjectTag) {
        for (const tagName of project.tags.subjectTag) {
          await TagManager.removeProjectTags(pin.project || '', tagName, pinKey);
        }
      }

      // Remove the pinKey from the project's pins array
      const projectRef = doc(db, 'Projects', pin.project || '');
      await updateDoc(projectRef, {
        pins: arrayRemove(pinKey)
      });

      // Delete the pin document
      const pinDocRef = doc(db, 'Pins', pinKey);
      await deleteDoc(pinDocRef);

    } catch (error) {
      console.error("Error removing pin:", error);
      throw error;
    }
  },

  getPinsByProjectKey: async (project: string): Promise<IPin[]> => {
    try {
      const pinsRef = collection(db, 'Pins');
      const q = query(pinsRef, where('project', '==', project));
      const querySnapshot = await getDocs(q);
      const pins: IPin[] = querySnapshot.docs.map(doc => doc.data() as IPin);
      return pins;
    } catch (error) {
      console.error("Error fetching pins by project key: ", error);
      return [];
    }
  },

  createPin: async (newPin: IPin): Promise<void> => {
    try {
      // Create a reference with the pinKey as the document ID
      const pinRef = doc(db, 'Pins', newPin.pinKey);
      
      // Use setDoc to set the data for the new document
      await setDoc(pinRef, newPin);

      // Check if project is not null before proceeding
      if (newPin.project) {
        // Get the project's subject tags
        const project = await ProjectManager.getProject(newPin.project);
        if (project && project.tags && project.tags.subjectTag) {
          const subjectTags = project.tags.subjectTag;

          // Update the Tags collection for each subject tag
          for (const tagName of subjectTags) {
            await TagManager.updateProjectTags(newPin.project, tagName, [newPin.pinKey]);
          }
        }
      } else {
        console.warn("Pin created without associated project");
      }
    } catch (error) {
      console.error("Error creating new pin: ", error);
      throw error;
    }
  },

  updatePin: async (updatedPin: IPin): Promise<void> => {
    if (!updatedPin.pinKey) {
      console.error('pinKey is undefined.');
      return;
    }
    const pinRef = doc(db, 'Pins', updatedPin.pinKey);
    try {
      // Here you would spread the updatedPin object to update all fields
      await updateDoc(pinRef, {
        ...updatedPin,

      });
    } catch (error) {
      console.error("Error updating pin: ", error);
      throw error;
    }
  },

  updateSequentialPinOrder: async (pins: IPin[]): Promise<void> => {
    const batch = writeBatch(db);

    pins.forEach((pin) => {
      if (!pin.pinKey || !pin.pinOrder) return;
      
      const pinRef = doc(db, 'Pins', pin.pinKey);
      
      console.log('📝 Updating Firestore order for pin:', {
        name: pin.name,
        currentOrder: pin.pinOrder,
      });

      batch.update(pinRef, { 
        pinOrder: {
          id: pin.pinOrder.id,
          branch: pin.pinOrder.branch
        }
      });
    });

    try {
      await batch.commit();
      console.log('✅ Batch update complete');
    } catch (error) {
      console.error('❌ Error updating pin orders:', error);
      throw error;
    }
  },
  getTotalDownloadsByAlbumKey: async (albumKey: string): Promise<number> => {
    try {
      const querySnapshot = await getDocs(query(collection(db, 'Recordings'), where('albumKey', '==', albumKey)));
      let totalDownloads = 0;
      querySnapshot.forEach((docSnapshot) => {
        const recording = docSnapshot.data() as IRecording;
        totalDownloads += recording.collectionLog?.timesCollected || 0;
      });
      return totalDownloads;
    } catch (error) {
      console.error("Error fetching total downloads by albumKey:", error);
      return 0;
    }
  },
};
