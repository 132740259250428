import { db } from '../firebase/firebase';
import { collection, getDocs, doc, getDoc, updateDoc, query, where, setDoc, DocumentReference, deleteDoc, writeBatch } from 'firebase/firestore';
import { IAuthor } from '../types/Author';
import { v4 as uuidv4 } from 'uuid';

export const AuthorManager = {
  getAllAuthors: async (): Promise<IAuthor[]> => {
    try {
      const snapshot = await getDocs(collection(db, 'Authors'));
      return snapshot.docs.map(doc => ({ authorKey: doc.id, ...doc.data() }) as IAuthor);
    } catch (error) {
      
      return [];
    }
  },

  getAuthor: async (authorKey: string): Promise<IAuthor | null> => {
    try {
      const authorDocRef = doc(db, 'Authors', authorKey);
      const docSnapshot = await getDoc(authorDocRef);
      if (docSnapshot.exists()) {
        return { authorKey: docSnapshot.id, ...docSnapshot.data() } as IAuthor;
      } else {
        
        return null;
      }
    } catch (error) {
      
      return null;
    }
  },

  updateAuthor: async (authorKey: string, updatedData: Partial<IAuthor>): Promise<void> => {
    try {
      
      
      
      const authorDocRef: DocumentReference = doc(db, 'Authors', authorKey);
      

      // Check if the document exists before updating
      const docSnapshot = await getDoc(authorDocRef);
      if (!docSnapshot.exists()) {
        throw new Error(`Author document with key ${authorKey} does not exist`);
      }

      // Remove any undefined or null values from updatedData
      const cleanedData: Partial<IAuthor> = {};
      for (const [key, value] of Object.entries(updatedData)) {
        if (value !== undefined && value !== null) {
          if (key === 'tags' && typeof value === 'object') {
            cleanedData.tags = value as { authorTags: string[] };
          } else {
            (cleanedData as any)[key] = value;
          }
        }
      }

      

      // Perform the update
      await updateDoc(authorDocRef, cleanedData);
      
    } catch (error) {
      console.error("AuthorManager: Error updating author:", error);
      if (error instanceof Error) {
        console.error("AuthorManager: Error message:", error.message);
        console.error("AuthorManager: Error stack:", error.stack);
      }
      if (error instanceof Error && 'code' in error) {
        console.error("AuthorManager: Error code:", (error as any).code);
      }
      throw error;
    }
  },
  getAuthorByName: async (name: string): Promise<IAuthor | null> => {
    try {
      const q = query(collection(db, 'Authors'), where('name', '==', name));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const docSnapshot = querySnapshot.docs[0];
        return { authorKey: docSnapshot.id, ...docSnapshot.data() } as IAuthor;
      } else {
        
        return null;
      }
    } catch (error) {
      
      return null;
    }
  },
// createAuthor: async (authorKey: string, newAuthorData: Partial<IAuthor>): Promise<IAuthor> => { - prevous version for reference
  createAuthor: async (authorKey: string, authorData: Omit<IAuthor, 'authorKey'>): Promise<string> => {
    try {
      const newAuthorRef = doc(db, 'Authors', authorKey);
      await setDoc(newAuthorRef, { 
        ...authorData, 
        authorKey, 
        isClaimed: authorData.isClaimed ?? false 
      });
      return authorKey; // Return the authorKey that was passed in
    } catch (error) {
      console.error("Error creating new author:", error);
      throw error;
    }
  },

  getAuthorsByName: async (name: string): Promise<IAuthor[]> => {
    try {
      const authorsRef = collection(db, 'Authors');
      const q = query(
        authorsRef, 
        where('name', '==', name)
      );
      const querySnapshot = await getDocs(q);
      
      // Filter the results to include only unclaimed authors
      return querySnapshot.docs
        .filter(doc => {
          const data = doc.data();
          return !('isClaimed' in data) || data.isClaimed === false || data.isClaimed === null;
        })
        .map(doc => ({ authorKey: doc.id, ...doc.data() } as IAuthor));
    } catch (error) {
      console.error("Error fetching authors by name:", error);
      return [];
    }
  },

  mergeAuthors: async (authorKeys: string[], userKey: string, finalAuthorKey: string): Promise<IAuthor> => {
    const batch = writeBatch(db);
    const authors = await Promise.all(authorKeys.map(key => getDoc(doc(db, 'Authors', key))));
    
    const mergedAuthor: IAuthor = {
      authorKey: finalAuthorKey,
      name: authors[0].data()?.name || '',
      userKey,
      tags: { authorTags: [] },
      isClaimed: true,
    };

    authors.forEach(author => {
      const data = author.data() as IAuthor;
      if (data.bio) mergedAuthor.bio = mergedAuthor.bio || data.bio;
      if (data.image) mergedAuthor.image = mergedAuthor.image || data.image;
      if (data.website) mergedAuthor.website = mergedAuthor.website || data.website;
      mergedAuthor.tags.authorTags = Array.from(new Set([...mergedAuthor.tags.authorTags, ...(data.tags?.authorTags || [])]));    });

    // Update or create the merged author document
    batch.set(doc(db, 'Authors', finalAuthorKey), mergedAuthor);

    // Delete other author documents
    authorKeys.forEach(key => {
      if (key !== finalAuthorKey) {
        batch.delete(doc(db, 'Authors', key));
      }
    });

    await batch.commit();
    return mergedAuthor;
  },

  claimAuthor: async (authorKey: string, userKey: string): Promise<void> => {
    try {
      const authorRef = doc(db, 'Authors', authorKey);
      await updateDoc(authorRef, { userKey, isClaimed: true });
      
    } catch (error) {
      console.error("Error claiming author:", error);
      throw error;
    }
  },

  deleteAuthor: async (authorKey: string): Promise<void> => {
    try {
      await deleteDoc(doc(db, 'Authors', authorKey));
      
    } catch (error) {
      console.error("Error deleting author:", error);
      throw error;
    }
  },

  getAuthorByUserKey: async (userKey: string): Promise<IAuthor | null> => {
    try {
      const authorsRef = collection(db, 'Authors');
      const q = query(authorsRef, where('userKey', '==', userKey));
      const querySnapshot = await getDocs(q);
      
      if (querySnapshot.empty) {
        return null;
      }

      const authorDoc = querySnapshot.docs[0];
      return { authorKey: authorDoc.id, ...authorDoc.data() } as IAuthor;
    } catch (error) {
      console.error("Error fetching author by userKey:", error);
      return null;
    }
  },

  getAuthorByKey: async (authorKey: string): Promise<IAuthor | null> => {
    try {
      const authorDocRef = doc(db, 'Authors', authorKey);
      const docSnapshot = await getDoc(authorDocRef);
      if (docSnapshot.exists()) {
        return { authorKey: docSnapshot.id, ...docSnapshot.data() } as IAuthor;
      } else {
        
        return null;
      }
    } catch (error) {
      console.error("Error fetching author by key:", error);
      return null;
    }
  },
};

export default AuthorManager;