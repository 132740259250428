import React, { FunctionComponent, useState, useCallback, useRef } from "react";
import PinEditor from "./PinEditor";
import PortalPopup from "./PortalPopup";
import "./ButtonRow.css";
import { useNavigate } from "react-router-dom";
import { Icon } from "./Icon";
import { useAdminNav } from '../hooks';
import { handleSavePin } from "../utilities/handleSavePin";
import { ProjectManager } from "../models/ProjectManager";
import Switch from '@mui/material/Switch'; 
import { CopyToClipboard } from 'react-copy-to-clipboard'; 
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

type ButtonRowType = {
  settingsIcon?: React.ReactNode;
  showAddNewPinButton?: boolean;
  showLocationInput?: boolean;
  defaultView?: 'map' | 'list' | 'branch';
  userRole?: string;
  isTransitProjectEnabled?: boolean;
  selectedProjectDetails?: {
    collectionLogic?: number;
    isTransitProjectEnabled?: boolean;
  };
  
  centerMapOnLocation?: (location: string) => void;
  onMapViewButtonClick?: () => void;
  onListViewButtonClick?: () => void;
  handleMapViewClick: any,
  handleListViewClick: any,
  containerWidth?: number; 
};

export const ButtonRow: FunctionComponent<ButtonRowType> = ({
  showAddNewPinButton,
  showLocationInput,
  centerMapOnLocation,
  defaultView,
  userRole,
  isTransitProjectEnabled,
  selectedProjectDetails,
}) => {
  const navigate = useNavigate();
  const [isPinEditorOpen, setPinEditorOpen] = useState(false);
  const [activeView, setActiveView] = useState<'map' | 'list' | 'branch'>(defaultView || 'map');
  const [locationInput, setLocationInput] = useState<string>('');
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);
  const [inviteLink, setInviteLink] = useState<string>('');
  const [isCopied, setIsCopied] = useState(false);
  const [accessLevel, setAccessLevel] = useState<'read' | 'write' | 'admin'>('read');
  const inviteLinkGenerated = useRef(false); 

  const handleLocationSubmit = useCallback(() => {
    if (centerMapOnLocation) {
      centerMapOnLocation(locationInput);
    }
  }, [centerMapOnLocation, locationInput]);

  const handleMapViewClick = useCallback((userRole: string) => {
    setActiveView('map');
    navigate("/map-view", { state: { userRole } });
  }, [navigate, setActiveView]);
  
  const handleListViewClick = useCallback((userRole: string) => {
    setActiveView('list');
    navigate("/pin-list-view", { state: { userRole } });
  }, [navigate, setActiveView]);

  const handleBranchViewClick = useCallback((userRole: string) => {
    setActiveView('branch');
    navigate("/branch-view", { state: { userRole } });
  }, [navigate, setActiveView]);

  const openPinEditor = useCallback(() => {
    setPinEditorOpen(true);
  }, []);

  const closePinEditor = useCallback(() => {
    setPinEditorOpen(false);
  }, []);

  const { selectedProject } = useAdminNav();

  const generateInviteLink = useCallback(async () => {
    if (selectedProject && !inviteLinkGenerated.current) {
      try {
        
        const link = await ProjectManager.createInviteLink(selectedProject, accessLevel);
        if (!link) {
          throw new Error('Invite link is undefined');
        }
        // Ensure the link starts with https://
        const secureLink = link.startsWith('https://') ? link : `https://${link}`;
        setInviteLink(secureLink);
        inviteLinkGenerated.current = true;
        
      } catch (error: unknown) {
        console.error("Error generating invite link:", error);
        setInviteLink(`Error generating link: ${error instanceof Error ? error.message : 'Unknown error'}`);
      }
    } else {
      
    }
  }, [selectedProject, accessLevel]);

  const handleAccessLevelChange = (event: SelectChangeEvent<'read' | 'write' | 'admin'>) => {
    setAccessLevel(event.target.value as 'read' | 'write' | 'admin');
    // Reset the invite link when access level changes
    setInviteLink('');
    inviteLinkGenerated.current = false;
  };

  const openSettings = useCallback(() => {
    setIsSettingsOpen(true);
    if (selectedProject) {
      ProjectManager.getProject(selectedProject).then(project => {
        setIsPrivate(project?.isPrivate || false);
        if (project?.isPrivate && !inviteLinkGenerated.current) {
          
          generateInviteLink();
        }
      });
    }
  }, [selectedProject, generateInviteLink]);

  const closeSettings = useCallback(() => {
    setIsSettingsOpen(false);
  }, []);

  const handlePrivacyToggle = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const newIsPrivate = event.target.checked;
    setIsPrivate(newIsPrivate);
    if (selectedProject) {
      await ProjectManager.updateProject(selectedProject, { isPrivate: newIsPrivate });
      if (newIsPrivate && !inviteLinkGenerated.current) {
        
        generateInviteLink();
      } else {
        setInviteLink('');
        inviteLinkGenerated.current = false; // Reset the flag
      }
    }
  }, [selectedProject, generateInviteLink]);

  console.log("Collection Logic:", selectedProjectDetails?.collectionLogic, 
    "Branch View should show:", selectedProjectDetails?.collectionLogic === 2);

  return (
    <div className="button-row-container">
      <div className="button-row-grid">
        <div className="left-section">
          <div className="settings-icon" onClick={openSettings}>
            <Icon icon="settings" size={40} color="black"/>
          </div>
        </div>
        
        <div className="center-section">
          <div className="toggle">
            <button 
              className={`view-button left ${activeView === 'map' ? 'active' : 'inactive'}`} 
              onClick={() => handleMapViewClick(userRole!)} 
            >
              <b>Map View</b>
            </button>
            <button 
              className={`view-button middle ${activeView === 'list' ? 'active' : 'inactive'}`} 
              onClick={() => handleListViewClick(userRole!)}
            >
              <b>List View</b>
            </button>
            {selectedProjectDetails?.collectionLogic === 2 && (
              <button 
                className={`view-button right ${activeView === 'branch' ? 'active' : 'inactive'}`} 
                onClick={() => handleBranchViewClick(userRole!)}
              >
                <b>Branch View</b>
              </button>
            )}
          </div>
        </div>
        
        <div className="right-section">
          {activeView === 'map' && (
            <input
              type="text"
              className="location-input"
              placeholder="Enter location to center map"
              value={locationInput}
              onChange={(e) => setLocationInput(e.target.value)}
              onKeyDown={e => {
                if (e.key === 'Enter') {
                  handleLocationSubmit();
                }
              }}
            />
          )}
          {activeView === 'list' && (
            <button className="button-action-submit" onClick={openPinEditor}>
              Add New Pin
            </button>
          )}
        </div>
      </div>
      
      {isPinEditorOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Top right"
          onOutsideClick={closePinEditor}
        >
          <PinEditor 
            onClose={closePinEditor} 
            onSave={(pin) => handleSavePin(pin, selectedProject, closePinEditor)}
            pinData={null}
          />
        </PortalPopup>
      )}
      
      {isSettingsOpen && (
        <PortalPopup
          overlayColor="rgba(113, 113, 113, 0.3)"
          placement="Centered"
          onOutsideClick={closeSettings}
        >
          <div className="settings-modal">
            <h2>Project Settings</h2>
            <div className="setting-item">
              <span>Private Project</span>
              <Switch
                checked={isPrivate}
                onChange={handlePrivacyToggle}
                inputProps={{ 'aria-label': 'controlled' }}
              />
            </div>
            {isPrivate && (
              <div className="invite-link-section">
                <h3>Invite Link</h3>
                <FormControl fullWidth>
                  <InputLabel id="access-level-select-label">Access Level</InputLabel>
                  <Select
                    labelId="access-level-select-label"
                    id="access-level-select"
                    value={accessLevel}
                    label="Access Level"
                    onChange={handleAccessLevelChange}
                  >
                    <MenuItem value="read">Read</MenuItem>
                    <MenuItem value="write">Write</MenuItem>
                    <MenuItem value="admin">Admin</MenuItem>
                  </Select>
                </FormControl>
                <button onClick={generateInviteLink} disabled={inviteLinkGenerated.current}>
                  Generate Invite Link
                </button>
                {inviteLink && (
                  <div>
                    <div  className="invite-link-container">
                    <input 
                      type="text" 
                      value={inviteLink} 
                      readOnly 
                      className="invite-link-input"
                    />
                    </div>
                    <CopyToClipboard 
                      text={inviteLink}
                      onCopy={() => {
                        setIsCopied(true);
                        setTimeout(() => setIsCopied(false), 2000);
                      }}
                    >
                      <button className="copy-button">
                        {isCopied ? 'Copied!' : 'Copy '}
                      </button>
                    </CopyToClipboard>
                  </div>
                )}
              </div>
            )}
            <button className="close-button" onClick={closeSettings}>Close</button>
          </div>
        </PortalPopup>
      )}
    </div>
  );
};

export default ButtonRow;
